@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {

    /* Custom Scrollbar Styles */
    .custom-scrollbar::-webkit-scrollbar {
      width: 8px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
      background: #000; /* Black background to match the page */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: #666; /* Dark gray for the scrollbar thumb */
      border-radius: 10px; /* Rounded edges for a modern feel */
      border: 2px solid #000; /* Adds a bit of spacing inside the track */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: #aaa; /* Lighter gray when hovering to give feedback */
    }

    /* For Firefox */
    .custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #666 #000; /* Thumb and track color for Firefox */
    }

    /* Hide scrollbar for specific elements if needed */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.toastProgress {
  background: #000 !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
